/* eslint-disable no-warning-comments */
/* eslint-disable react-hooks/rules-of-hooks */

import './src/styles/globals.scss'

import Lenis from '@studio-freight/lenis'
import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import * as React from 'react'

export const wrapPageElement = ({element}) => {
  React.useEffect(() => {
    const lenis = new Lenis({
      lerp: undefined,
      duration: 2,
      // https://easings.net/
      easing: (x) => (x === 1 ? 1 : 1 - Math.pow(2, -10 * x)),
    })

    lenis.on('scroll', ScrollTrigger.update)

    function onWheel(event) {
      const paths = event.composedPath()

      paths.forEach((el) => {
        if (el instanceof HTMLElement && el?.getAttribute('id') === 'headlessui-portal-root') {
          event.stopPropagation()
        }
      })
    }

    lenis.options.content.addEventListener('wheel', onWheel)

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000)
    })

    gsap.ticker.lagSmoothing(0)

    return () => {
      lenis.options.content.removeEventListener('wheel', onWheel)
    }
  }, [])

  return element
}
